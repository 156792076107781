<template>
    <div :class="`box-act act-finished mr-2 cursor-pointer bg-${currentTheme}`" v-if="status === 'success'">
      <div class="p-5">
        <div class="info-text">{{ actName }}</div>
        <div class="grand-text">{{ title }}</div>
      </div>
      <div class="flex mr-12 ">
        <span class="info-text mr-2">TERMINÉ</span>
        <img src="~@/assets/icons/ico-check.svg">
      </div>
    </div>
    <div :class="'box-act act-next mr-2'" v-if="status === 'pending'">
      <ShadowButton width="100%" :theme="`light-yellow-${currentTheme}`" >
        <div :class="`p-5 font-bariol text-left text-${currentTheme}`">
          <img :src="illustrationpath" class="mb-5 h-full w-full">
          <div class="flex items-center justify-between">
            <div class="">
              <div class="info-text">{{ actName }}</div>
              <div class="grand-text">{{ title }}</div>
            </div>
            <div class="flex">
              <span class="link-bold mr-2">Commencer</span>
              <img src="~@/assets/icons/icon-fleche-bas-blue-quest.svg" v-if="currentTheme === 'blue-quest'">
              <img src="~@/assets/icons/icon-fleche-bas-yellow-quest.svg" v-if="currentTheme === 'yellow-quest'">
              <img src="~@/assets/icons/icon-fleche-bas-pink-quest.svg" v-if="currentTheme === 'pink-quest'">
              <img src="~@/assets/icons/icon-fleche-bas-green-quest.svg" v-if="currentTheme === 'green-quest'">
            </div>
          </div>
        </div>
      </ShadowButton>
    </div>
    <div :class="`box-act act-locked mr-2 mb-10 text-${currentTheme}-dark bg-${currentTheme}`" v-if="status === 'locked'">
      <div class="p-5">
        <div class="text-xs">{{ actName }}</div>
        <div class="text-md font-bold">{{ title }}</div>      </div>
      <div class="flex mr-12 ">
        <img src="~@/assets/icons/icon-lock-blue-quest-dark.svg" v-if="currentTheme === 'blue-quest'">
        <img src="~@/assets/icons/icon-lock-yellow-quest-dark.svg" v-if="currentTheme === 'yellow-quest'">
        <img src="~@/assets/icons/icon-lock-green-quest-dark.svg" v-if="currentTheme === 'green-quest'">
        <img src="~@/assets/icons/icon-lock-pink-quest-dark.svg" v-if="currentTheme === 'pink-quest'">
      </div>
    </div>

</template>

<script>
import { getMediaFromId } from "../../utils/apollo-parser";

import ShadowButton from "../ShadowButton";
import {mapState} from "vuex";

export default {
  components: {
    ShadowButton
  },
  props: {
    actName:{
      type: String,
    },
    title: {
      type: String,
    },
    status: {
      type: String,
    },
    illustration: {
      type: String,
      default: null
    }
  },
  computed:{
    ...mapState({
      currentTheme: state => state.currentTheme,
      currentAct: state => state["enquete/enquete"].acts[0]
    }),
    illustrationpath () {
      return this.illustration || require('../../assets/pics/act/illus.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.box-act{
  @apply flex justify-between text-left rounded-md items-center mt-6
}
.act-finished {
  @apply font-bariol text-yellow-light
}
.act-locked {
  @apply font-bariol
}
.act-next {
  @apply font-bariol
}
</style>
