<template>
  <div :class="` absolute w-screen h-screen flex text-yellow-light justify-start ${currentTheme}`">
  <div :class="`z-0 overflow-y-auto absolute w-full h-full flex text-yellow-light bg-${currentTheme}-light bg-cover bg-opacity-90 justify-start items-start`" :style="`background: bottom right 10% / contain  no-repeat url(${enquete.illustration})`">
    <div class="`site-content flex flex-1 text-left justify-start pb-20 w-screen  text-yellow-light `">
      <div class="w-8/12 1280:w-1/2 1480:w-4/12 mt-20 pl-20 justify-start">
        <h2 class="mt-4 medium-title text-center">{{title}}</h2>
        <div class="mb-10 mt-5 text-center font-bariol text-xs text-yellow-light ">
          <span :class="`rounded-md ml-2 bg-${currentTheme} pr-2 p-3 mb-2 mr-auto ml-auto w-auto small-body-text`">
            {{sequencesCount}}{{$t.acte.indicateur_actes}}
          </span>
        </div>
        <ShadowButton class="pr-2" :theme="`${currentTheme}-light-yellow`" @click="$emit('nextstep', {action: 'prev', transition: 'down'})">{{$t.buttons.bouton_retour}}</ShadowButton>
        <div class="text-center mr-auto ml-auto pb-10" v-if="module && module.sequences">
          <!-- status: success, pending, locked -->
          <div @click="loadQuest(act.id)" v-for="(act, index) of module.sequences" :key="act" >
              <ActsButton :status="statusActs(act.id)" :act-name="'ACTE ' + (index + 1)" :title="act.title" :illustration="actsIllustrations[act.id] || ''"></ActsButton>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { getMediaFromId } from "../../utils/apollo-parser";

import ActsButton from "../../components/acts/ActsButtons";
import {mapState} from "vuex";
import { setSequence, userHasSequence, getUserSequences } from '@/composables/user_log';

export default {
  emits: ["nextstep"],
  components: {
     ActsButton
  },
  props: {
    title: String,
    acts: Object,
    illus: String,
    courses: {
      type: Object,
      default: undefined
    },
    moduleId:{
      type: String,
      default: undefined
    },
  },
  data() {
    return {
      actsIllustrations: {},
      sequencesCount: 0
    }
  },
  computed: {
    module() {

      console.log('enquete', this.enquete);
      return this.courses && this.moduleId ? this.courses[0].modules.find( m => m.id === this.moduleId) : undefined
    },
    // sequencesCount() {
    //     let count = 0
    //     module.sequences.forEach(sequence => {
    //       const act = userHasSequence(sequence.id)
  
    //       if (act && act.data.status != 'pending' || act.data.status != 'locked'){
    //         count++
    //       }
  
    //     });
    //     return count
    // },
    ...mapState({
        enquete: state => state.enquete.enquete,
        currentTheme: state => state.currentTheme,
        actsState: state => state["enquete/enquete"].acts,
        currentModule: state => state.gamestatus.currentModule,
        currentSequence: state => state.gamestatus.currentSequence,
        currentCourseType: state => state.gamestatus.currentCourseType,
      }),
    firstPendingSequence() {
      return this.module.sequences.find(sequence => {
        const sequenceProgressionData = userHasSequence(sequence.id)

        return (!sequenceProgressionData || sequenceProgressionData.data.status == 'pending')
      })
    }
  },
    watch: {
      module: {
        async handler(module) {
          if (!module || !module.sequences || module.sequences.length < 0) {
            this.actsIllustrations = []
            return
          }
        this.sequencesCount = 0

        // Load illustrations asynchronously
        for (var i = 0; i < module.sequences.length; i++) {
          const act = module.sequences[i]
          if (act){
            const sequence = userHasSequence(act.id)
            if (typeof sequence !== 'undefined' && sequence && sequence.data.status != 'pending' && sequence.data.status != 'locked'){
              this.sequencesCount++
            }
          }
          if (act.metas && act.metas.thumbnail) {
            const mediaQueryResult = await getMediaFromId(act.metas.thumbnail)

            this.actsIllustrations[act.id] = process.env.VUE_APP_MEDIA_URL + mediaQueryResult.data.item.path
          } else {
            this.actsIllustrations[act.id] = ''
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    async loadQuest(currentSequence) {
      if (this.statusActs(currentSequence) !== 'locked') {
          setSequence(currentSequence);
          await this.$store.dispatch('gamestatus/setGame', {currentCourseType: this.courses[0].type.slug, currentSequence: currentSequence, currentModule: this.moduleId} )
          this.$router.push({name: 'games/GameWrapper'});
      }
    },

    statusActs(sequence_id) {

      const sequence = userHasSequence(sequence_id);

      if (sequence) {
        return sequence.data.status;
      } else if (this.firstPendingSequence.id == sequence_id) {
        // If it's the first pending sequence
        return 'pending';
      }

      return 'locked';
    }
  }
}
</script>

<style lang="scss" scoped>
.blue-quest {
    @apply  bg-blue-quest-light
}
.green-quest {
    @apply  bg-green-quest-light
}
.yellow-quest {
    @apply  bg-yellow-quest-light
}
.pink-quest {
    @apply  bg-pink-quest-light
}
</style>
